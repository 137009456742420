import React from 'react'
import {
  AccountSwitcher,
  Avatar,
  Dropdown,
  IconButton,
  Item,
  Popover,
  useDropdown,
} from '@revolut/ui-kit'

import { useGetCompanyLogo, useGetCompanyPreferences } from '@src/api/settings'
import { ROUTES, WORKSPACES } from '@src/constants/routes'
import { Workspace, useWorkspaceContext } from '@src/features/Workspaces/WorkspaceContext'
import { Environments, env } from '@src/constants/api'
import { useRemoveWorkspacePopup } from '@src/features/Workspaces/common'
import { RemoveAccountItem } from './RemoveAccountItem'

export const TopNavAccountSwitcher = () => {
  const { data: companyLogoData, isLoading: isCompanyLogoLoading } = useGetCompanyLogo()
  const { data: companyPreferencesData, isLoading: isCompanyPreferencesLoading } =
    useGetCompanyPreferences()

  const { toggle, ...dropdown } = useDropdown({
    disabled:
      env === Environments.productionCommercial ||
      env === Environments.productionCommercialRoot,
  })

  const workspaceContext = useWorkspaceContext()

  if (isCompanyLogoLoading || isCompanyPreferencesLoading) {
    return <AccountSwitcher pending />
  }

  return (
    <>
      <AccountSwitcher
        image={companyLogoData?.url || undefined}
        {...dropdown.getAnchorProps()}
      >
        {companyPreferencesData?.brand_name}
      </AccountSwitcher>

      <Popover
        {...dropdown.getTargetProps()}
        title="Revolut People accounts"
        maxWidth={712}
        minWidth={356}
        width={null}
        mobileBehaviour="bottom-sheet"
      >
        {workspaceContext?.workspaces?.length ? (
          <Popover.Group>
            {workspaceContext.workspaces.map(workspace => {
              return (
                <AccountItem
                  workspace={workspace}
                  onCloseAccountSwitcher={() => toggle(false)}
                  key={workspace.name}
                />
              )
            })}
          </Popover.Group>
        ) : null}
        <Popover.Group>
          <Popover.Item
            use="button"
            useIcon="Plus"
            onClick={() => {
              window.location.href = WORKSPACES.MAIN
            }}
          >
            Add account
          </Popover.Item>
        </Popover.Group>
      </Popover>
    </>
  )
}

interface AccountItemProps {
  workspace: Workspace
  onCloseAccountSwitcher: () => void
}

const AccountItem = ({ workspace, onCloseAccountSwitcher }: AccountItemProps) => {
  const moreInfoDropdown = useDropdown()
  const workspaceContext = useWorkspaceContext()
  const removeWorkspacePopup = useRemoveWorkspacePopup()

  const currentWorkspace = workspace.name === workspaceContext?.workspace
  const workspaceCompanyName = workspace.companyName || workspace.name

  return (
    <>
      <Item
        use="button"
        onClick={() => {
          window.location.href = `${document.location.origin}/${workspace.name}${ROUTES.LOGIN.MAIN}`
        }}
      >
        <Item.Avatar>
          <Avatar
            image={workspace.icon}
            useIcon={workspace.icon ? undefined : 'CompanyFilled'}
          >
            {currentWorkspace ? <Avatar.Badge useIcon="Check" /> : null}
          </Avatar>
        </Item.Avatar>
        <Item.Content>
          <Item.Title>{workspaceCompanyName}</Item.Title>
          <Item.Description>{workspace.email}</Item.Description>
        </Item.Content>
        <Item.Side>
          <IconButton
            {...moreInfoDropdown.getAnchorProps()}
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()

              moreInfoDropdown.toggle()
            }}
            aria-label="Actions"
            useIcon="Ellipsis"
          />
        </Item.Side>
      </Item>

      <Dropdown {...moreInfoDropdown.getTargetProps()} placement="bottom-start">
        <RemoveAccountItem
          onClick={() => {
            onCloseAccountSwitcher()
            removeWorkspacePopup?.remove(workspace.name)
          }}
        />
      </Dropdown>
    </>
  )
}
