import React from 'react'
import { MeetingsTracker } from '../Forms/MeetingsTracker/MeetingsTracker'
import { ROUTES } from '@src/constants/routes'
import { Route, Switch } from 'react-router-dom'
import { EmployeeInterface } from '@src/interfaces/employees'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { TeamInterface } from '@src/interfaces/teams'
import { MeetingTrackerEntity } from '@src/interfaces/meetingsTracker'

interface Props {
  data: EmployeeInterface | DepartmentInterface | TeamInterface
  meetingEntityType: MeetingTrackerEntity
}

export const MeetingLayout = ({ data, meetingEntityType }: Props) => {
  return (
    <>
      <Switch>
        <Route
          exact
          path={ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.MEETINGS.OVERVIEW}
        >
          <MeetingsTracker data={data} meetingEntityType={meetingEntityType} />
        </Route>
      </Switch>
    </>
  )
}
